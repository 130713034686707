/**
 * Site navigation JavaScript
 */
import event from "../../../../../../../clientlibs/v1/publish/js/utils/publisherSubscriber";

const searchOpener = document.querySelector(".search-opener");
const quicksearchfield = document.querySelector('#quicksearch-input');
const mobileNavOpener = document.querySelector(".mobile-nav-opener");
const mainNav = document.querySelector("#main-navigation");
const headerSearch = document.querySelector(".site-search");
const mainPageContainer = document.querySelector(".container");
const primaryNavItems = document.querySelectorAll(".nav-level--0 > span.primary > span.icon-chevron");
const secondaryNavItems = document.querySelectorAll(".nav-level--1 > span.secondary > span.icon-plus");

let isTouchDevice = document.getElementsByTagName("body")[0].classList.contains("touch-device");

let findParentEL = (rootel, findelclass) => {
    while (rootel.parentNode) {
        rootel = rootel.parentNode;
        if (rootel.classList.contains(findelclass))
            return rootel;
    }
    return null;
}

export default (function buildHeader() {
    if (mainNav) {
        let deviceClass = (isDesktop) => {
            isDesktop.isDesktop == true || isDesktop == true
                ? (document.querySelector("body").classList.add("desktop"),
                    document.querySelector("body").classList.remove("mobile"),
                    subMenuTouch(isDesktop.isDesktop, isTouchDevice))
                : (document.querySelector("body").classList.remove("desktop"),
                    document.querySelector("body").classList.add("mobile"),
                    subMenuTouch(isDesktop.isDesktop, isTouchDevice));
        };

        window.addEventListener('load', () => {
            document.querySelector('.header_v2').classList.remove('no-js');
            document.querySelectorAll("body")[0].classList.contains("mobile") ? deviceClass(false) : deviceClass(true);

            if (window.innerWidth < 1060) {
                document.querySelector('.cmp-header_v2-wrapper').prepend(document.querySelector('.icons'))
            } else {
                if (document.querySelector('.cmp-header_v2-wrapper .icons'))
                    document.querySelector('.cmp-market-picker__opener-wrapper').prepend(document.querySelector('.icons'))
            }
        });

        window.onresize = () => {

            if (window.innerWidth < 1060) {
                document.querySelector('.cmp-header_v2-wrapper').prepend(document.querySelector('.icons'))
            } else {
                if (document.querySelector('.cmp-header_v2-wrapper .icons'))
                    document.querySelector('.cmp-market-picker__opener-wrapper').prepend(document.querySelector('.icons'))
            }

            if (document.querySelectorAll('.sub-nav-open').length > 0) document.querySelectorAll('.sub-nav-open').forEach(navopen => navopen.classList.remove('sub-nav-open'));
            if (document.querySelectorAll('.sub-sub-nav-open').length > 0) document.querySelectorAll('.sub-sub-nav-open').forEach(navopen => navopen.classList.remove('sub-sub-nav-open'));
        }

        let subMenuTouch = (isDesktop, isTouchDevice) => {
            addRemoveNavTouchEvents("primary", primaryNavItems, isDesktop, isTouchDevice);

            addRemoveNavTouchEvents("secondary", secondaryNavItems, isDesktop, isTouchDevice);
        };

        let processChildUls = (item, childUls) => {
            item.addEventListener("click", (event) => {
                event.stopImmediatePropagation();
                addRemoveAriaExpanded(item);
                // preventdefault cant exactly be undone
                item.onclick = () => {
                    return false;
                };

                childUls.classList.contains("open")
                    ? (childUls.classList.remove("open"), childUls.parentElement.classList.remove("open-child"))
                    : (item.parentElement.parentElement.parentElement.querySelectorAll("ul.open").forEach((item) => {
                        item.classList.remove("open");
                        item.parentElement.classList.remove("open-child");
                    }),
                        childUls.classList.add("open"),
                        childUls.parentElement.classList.add("open-child"));
            });
        };

        let addRemoveAriaExpanded = (whatelment, whatvalue) => {
            whatelment.setAttribute("aria-expanded", whatelment.getAttribute("aria-expanded") == "true" ? "false" : "true");
        };
        let addRemoveNavTouchEvents = (id, navItems, isDesktop, isTouchDevice) => {
            if (isTouchDevice) {
                navItems.forEach((item) => {
                    item.setAttribute("aria-expanded", false);
                    item.setAttribute(
                        "aria-controls",
                        item.parentElement.parentElement.querySelector("ul").getAttribute("id")
                    );
                    let itemsChildUl = item.parentElement.parentElement.querySelector(".submenu-holder");
                    let itemsGrandchildChildUl = itemsChildUl.parentElement.querySelectorAll("ul")[0];
                    if (id === "primary" && itemsChildUl !== undefined) {
                        processChildUls(item, itemsChildUl);
                    }
                    if (id === "secondary" && itemsGrandchildChildUl !== undefined) {
                        processChildUls(item, itemsGrandchildChildUl);
                    }
                });
            } else {
                // it is a desktop, so you can do something else here
            }
        };

        // search opener
        searchOpener.addEventListener(
            "click",
            (event) => {
                headerSearch.classList.toggle("show");
                quicksearchfield.focus();
                nudgeNavSearch();
            },
            false
        );

        if(quicksearchfield != null){
	        quicksearchfield.addEventListener('blur', (event) => {
	            // headerSearch.classList.remove("show");
	        })
        }

        // mobile nav opener
        mobileNavOpener.addEventListener(
            "click",
            (event) => {
                event.currentTarget.classList.toggle('open');
                mainNav.classList.toggle("show");
                nudgeNavSearch();
            },
            false
        );
        /**
         * STARTED FIX FOR SHOW HIDE TERTIARY NAV ON MOB
        document.querySelectorAll('.secondary > a:first-child').forEach(item =>{ item.appendChild("<span class='icon-chevron'></span>"); });
        document.querySelector('.secondary > a:first-child').addEventListener('click', e => {
            e.currentTarget.parent().classList.toggle('open-nav-level--2');
        })
        **/
        // check if search AND nav is open at mobile
        // if so move the nav down to prevent search covering the nav
        let nudgeNavSearch = () => {
            if (
                // document.getElementById("main-navigation").classList.contains("show") &&
                document.getElementById("site-search").classList.contains("show")
            ) {
                document.getElementById("main-navigation").classList.add("search-is-open");
            } else {
                document.getElementById("main-navigation").classList.remove("search-is-open");
            }
        };

        let scTopMenuItems = document.querySelectorAll(".nav-level--0");
        if (scTopMenuItems.length > 0) {
            scTopMenuItems.forEach((sc, i) => {
                if (i == 0) {
                    sc.classList.add('first');
                }
                else if (i == scTopMenuItems.length-1) {
                    sc.classList.add('last');
                }
            });
        }

        let scSubMenuItems = document.querySelectorAll(".submenu-holder");
        if (scSubMenuItems.length > 0) {
            scSubMenuItems.forEach((sc, i) => {
                if (i == 0) {
                    sc.classList.add('first');
                }
                else if (i == scSubMenuItems.length-1) {
                    sc.classList.add('last');
                }
            });
        }

        document.querySelectorAll('.nav-level--0.parent .primary .icon-chevron').forEach(a => {
            a.addEventListener('click', e => {
                findParentEL(e.currentTarget, 'nav-level--0').querySelector('.submenu-holder').classList.toggle('sub-nav-open');
                findParentEL(e.currentTarget, 'nav-level--0').querySelector('.icon-chevron').classList.toggle('open');
            })
        });

        document.querySelectorAll('.nav-level--1.parent .secondary .icon-chevron').forEach(a => {
            a.addEventListener('click', e => {
                findParentEL(e.target, 'nav-level--1').querySelector('.sub-submenu-holder').classList.toggle('sub-sub-nav-open');
                findParentEL(e.target, 'nav-level--1').querySelector('.icon-chevron').classList.toggle('open');
            })
        });


        // Subscribe to viewport events
        // Receive initial viewport information
        event.subscribe("/mediaquery/loaded", (data) => {
            deviceClass(data);
        });

        // Receive notification of viewport change from mobile to desktop, or vice versa
        // Ignore other registered breakpoint changes
        event.subscribe("/mediaquery/crossover", (data) => {
            deviceClass(data);
        });
    }
})();
