import cookiehelper from "../../../../../../../clientlibs/v1/publish/js/utils/cookies";
import cookie from "../../../../../../content/cookie/clientlibs/v1/publish/js/cookie";

export default (function marketPicker() {
    const component = [];
    component["name"] = "market-picker";
    if (document.querySelector("." + component.name)) {
        const countryBtn = document.querySelectorAll(".cmp-market-picker__country");
        const revealInvestorsBtn = document.querySelectorAll(".reveal-investors");
        const openerBtn = document.querySelector(`.cmp-${component.name}__opener`);
        const closerBtn = document.querySelector(`.cmp-${component.name}__closer`);
        const backBtn = document.querySelector("#important-information--back");
        const loadInvestorRoot = document.querySelectorAll(".investors__load-investor-root");
        const acceptBtn = document.querySelector("#important-information--accept");
        // const declineBtn = document.querySelector("#important-information--decline");
        const showLanguages = document.querySelector(`.cmp-${component.name}__language-opener`);
        const languages = document.querySelector(`.cmp-${component.name}__languages`);
        const authorqs = document.querySelector("html[data-mode='author']") || new URLSearchParams(window.location.search).get("wcmmode") != null ? "?wcmmode=disabled" : "";

        const cookieNameCountry = "soscot_country";
        const cookieNameLanguage = "soscot_language";
        const cookieNameInvestorType = "soscot_investor_type";
        const cookieNameSite = "soscot_site";

        revealInvestorsBtn.forEach((item) => {
            item.addEventListener("click", (e) => {
                if (document.querySelectorAll('.country-selected').length > 0) document.querySelector('.country-selected').classList.remove('country-selected');
                e.currentTarget.classList.add('country-selected');
                if (document.querySelectorAll('.terms-selected').length > 0) document.querySelector('.terms-selected').classList.remove('terms-selected');

                document.querySelectorAll(".investors").forEach((el) => {
                    el.classList.add("hidden");
                });
                let selector = "#market_investors_" + e.target.dataset.market_investors;
                document.querySelector(selector + ' .investors__load-investor-root').click();

                cookiehelper.set("email-link", false);
            });
        });

        openerBtn.addEventListener("click", (e) => {
               document.querySelector(`.cmp-${component.name}`).classList.add("opened");
               document.querySelector(`html`).classList.add("mp-open");
               document.querySelector('#main-navigation').classList.add('open-market-picker');
        });

        if(closerBtn != null){
	        closerBtn.addEventListener("click", (e) => {
	            document.querySelector(`.cmp-${component.name}`).classList.remove("opened");
	            document.querySelector(`html`).classList.remove("mp-open");
	            document.querySelector('#main-navigation').classList.remove('open-market-picker');
	        });
        }
        loadInvestorRoot.forEach((item) => {
            item.addEventListener("click", (e) => {
                if (!e.currentTarget.classList.contains('terms-selected')) {
                    if (document.querySelectorAll('.terms-selected').length > 0) document.querySelector('.terms-selected').classList.remove('terms-selected');
                    e.currentTarget.classList.add('terms-selected');
                    e.preventDefault();
                    let $this = e.target;
                    let url = $this.dataset.xf_path;
                    let href = $this.dataset.href + authorqs;
                    let investorType = e.currentTarget.dataset.investor_type;
                    let country = e.currentTarget.dataset.country;
                    let language = e.currentTarget.dataset.language;
                    let site = e.currentTarget.dataset.site;
                    if (
                        typeof url !== "undefined" &&
                        typeof href !== "undefined" &&
                        (typeof cookiehelper.get(`soscot_${country}_${language}_${investorType}_terms_agreed`) === 'undefined' ||
                            cookiehelper.get(`soscot_${country}_${language}_${investorType}_terms_agreed`) !== "true")
                    ) {
                        // hide close of market picker until the aagree
                       // document.querySelector(".cmp-market-picker__closer").classList.add('hide'); //.style.display = "none";

                        fetch(url + authorqs)
                            .then((response) => {
                                return response.text();
                            })
                            .then((html) => {
                                var parser = new DOMParser();
                                var parsedTermsHtml = parser.parseFromString(html, "text/html");
                                let termTextBlock = parsedTermsHtml.querySelector(
                                    'div.container .text[data-component="text"]'
                                );

                                document
                                    .querySelector(`.cmp-${component.name}__important-information`)
                                    .classList.add("loaded");
                                document.querySelector("#important-information").appendChild(termTextBlock);
                            })
                            .catch((err) => {
                                console.warn("Error retreiving terms XF!", err);
                            });
                        if (cookiehelper.get("email-link") == "true") {
                            let qs = window.location.search.indexOf("wcmmode=disabled") > -1 ? window.location.search.replace('?wcmmode=disabled', "").replace('&wcmmode=disabled', "") : window.location.search;
                            let fixedAuthorQs = (qs.length > 1 ? '&' : '?') + authorqs.replace('?', '');
                            let finalQs = qs + fixedAuthorQs;
                            href = window.location.pathname + finalQs.length > 1 ? finalQs : '';

                        }

                        /**
                         * As investor type had been required by the SI instance of MP and that button has been hidden here,
                         * the default url was being lost on initial load.  This fix takes the data-href value from the
                         * hidden investor type which was hit with a programatic click event.
                         */
                        if (href == null || href == "") href = e.currentTarget.getAttribute('data-href');

                        acceptBtn.setAttribute("href", href);
                        acceptBtn.setAttribute("data-country", e.currentTarget.getAttribute("data-country"));
                        acceptBtn.setAttribute("data-language", e.currentTarget.getAttribute("data-language"));
                        acceptBtn.setAttribute("data-investor_type", e.currentTarget.getAttribute("data-investor_type"));
                        // declineBtn.setAttribute("href", "/");
                    } else {
                        const current_site_country = document
                            .querySelector("[data-site-country]")
                            .dataset.siteCountry.toLowerCase()
                            .toLowerCase();
                        cookiehelper.set(cookieNameCountry, country)
                        cookiehelper.set(cookieNameInvestorType, investorType);
                        cookiehelper.set(cookieNameLanguage, language);
                        cookiehelper.set(cookieNameSite, site);
                        if ($this.getAttribute("href") == "" || $this.getAttribute("href") == null) {
                            let investorType = e.currentTarget.dataset.investor_type;
                            let country = e.currentTarget.dataset.country;
                            let language = e.currentTarget.dataset.language;

                            if (cookiehelper.get("email-link") == "true") {
                                let qs = window.location.search.indexOf("wcmmode=disabled") > -1 ? window.location.search.replace('?wcmmode=disabled', "").replace('&wcmmode=disabled', "") : window.location.search;
                                let fixedAuthorQs = (qs.length > 1 ? '&' : '?') + authorqs.replace('?', '')
                                let finalQs = qs + fixedAuthorQs;
                                href = window.location.pathname + finalQs.length > 1 ? finalQs : '';
                            }
                            if(href == ""){
                            	let authPath = '';
                                let countryChangePath = '';
                                if (window.location.href.indexOf('/content/scottish-oriental') > -1) {
                                    authPath = '/content/scottish-oriental';
                                    countryChangePath = authPath + '/' + e.currentTarget.dataset.country + '/' + e.currentTarget.dataset.language + window.location.href.substring(window.location.href.indexOf('/en/institutional') + 3);
                                }
                                else if (window.location.href.indexOf('www.igneoip.com') > -1) {
                                    authPath = '/content/scottish-oriental';
                                    if(window.location.href.indexOf('/en/institutional') > -1)
                                    {
                                        countryChangePath = authPath + '/' + e.currentTarget.dataset.country + '/' + e.currentTarget.dataset.language + window.location.href.substring(window.location.href.indexOf('/en/institutional') + 3);
                                        }
                                    else{
                                     countryChangePath = authPath + '/' + e.currentTarget.dataset.country + '/' + e.currentTarget.dataset.language + '/' + e.currentTarget.dataset.investor_type + '.html';
                                    }
                                }
                                href = countryChangePath;
                               }
                            window.location = href;
                        } else {
                            window.location = $this.getAttribute("href") + authorqs;
                        }
                        document.querySelector(".cmp-market-picker__closer").classList.remove('hide')
                    }
                }

            });
        });

        if (showLanguages) {
            showLanguages.addEventListener("click", (e) => {
                languages.classList.toggle("slideInDown");
                languages.classList.toggle("slideOutUp");
                e.currentTarget.classList.toggle(`cmp-${component.name}__language-opener--closed`);
                languages.setAttribute(
                    "aria-expanded",
                    e.currentTarget.classList.contains(`cmp-${component.name}__language-opener--closed`)
                        ? "false"
                        : "true"
                );
            });
        }

        acceptBtn.addEventListener("click", (e) => {
            const cookied_country = cookiehelper.get(cookieNameCountry)
                ? cookiehelper.get(cookieNameCountry).toLowerCase()
                : null;
            const cookied_language = cookiehelper.get(cookieNameLanguage)
                ? cookiehelper.get(cookieNameLanguage).toLowerCase()
                : null;
            const cookied_investor_type = cookiehelper.get(cookieNameInvestorType)
                ? cookiehelper.get(cookieNameInvestorType).toLowerCase()
                : null;

            cookiehelper.expire(`soscot_${cookied_country}_${cookied_language}_${cookied_investor_type}_terms_agreed`);

            let investorType = e.currentTarget.dataset.investor_type;
            let country = e.currentTarget.dataset.country;
            let language = e.currentTarget.dataset.language;
            const current_site_country = document
                .querySelector("[data-site-country]")
                .dataset.siteCountry.toLowerCase()
                .toLowerCase();

            cookiehelper.set(cookieNameCountry, country)
            cookiehelper.set(cookieNameInvestorType, investorType);
            cookiehelper.set(cookieNameLanguage, language);

            cookiehelper.set(`soscot_${country}_${language}_${investorType}_terms_agreed`, "true");
        });

        countryBtn.forEach((item) => {
            if (window.location.href.indexOf("/" + item.getAttribute("data-country") + "/") > -1) {
                item.classList.add("active-country");
            }
            item.addEventListener("click", (e) => {
                if (document.querySelector(".active-country")) {
                    document.querySelector(".active-country").classList.remove("active-country");
                }
                if (window.location.href.indexOf('/en/institutional') > -1) {
                    // /europe/en/institutional/our-offering/why-infrastructure.html
                    let authPath = '';
                    if (window.location.href.indexOf('/content/scottish-oriental') > -1) authPath = '/content/scottish-oriental';
                    let countryChangePath = authPath + '/' + e.currentTarget.dataset.country + '/' + e.currentTarget.dataset.language + window.location.href.substring(window.location.href.indexOf('/en/institutional') + 3);
                    acceptBtn.setAttribute("href", countryChangePath);
                }
                e.currentTarget.classList.toggle("active-country");
                document.querySelector("#important-information").innerHTML = "";
                document.querySelector(`.cmp-${component.name}__important-information`).classList.remove("loaded");
            });
        });

        document.querySelectorAll(".cmp-market-picker__country").forEach((mainitem) => {
            mainitem.addEventListener("mouseover", (e) => {
                document.querySelectorAll(".cmp-market-picker__country").forEach((item) => {
                    item.classList.add("forced-inactive");
                });
                e.currentTarget.classList.add("temp-active");
            });
            mainitem.addEventListener("mouseout", (e) => {
                document.querySelectorAll(".cmp-market-picker__country").forEach((item) => {
                    item.classList.remove("forced-inactive");
                });
                e.currentTarget.classList.remove("temp-active");
            });
        });

        /** mobile jazz */
        document.querySelector(".mobile-wrapper").addEventListener("click", (e) => {
            if (!e.target.classList.contains("cmp-market-picker__language-opener")) {
                e.currentTarget.classList.toggle("mobile-click");
                document
                    .querySelector(".cmp-market-picker__wrapper__investors-container")
                    .classList.remove("country-selected");
                if (e.currentTarget.classList.contains("mobile-click")) {
                    document.querySelector(".mobile-wrapper").setAttribute("data-before", "Select country");
                } else {
                    document.querySelector(".mobile-wrapper").setAttribute("data-before", "Select investor type");
                }
            }
        });
        document.querySelectorAll(".mobile-wrapper .cmp-market-picker__country.reveal-investors").forEach((item) => {
            item.addEventListener("click", (e) => {
                document.querySelector(".mobile-click")
                    ? document.querySelector(".mobile-click").classList.remove(".mobile-click")
                    : null;
                document
                    .querySelector(".cmp-market-picker__wrapper__investors-container")
                    .classList.add("country-selected");
                document.querySelector(".mobile-wrapper").setAttribute("data-before", "Select investor type");
            });
        });
        // document
        //     .querySelector(".mobile-wrapper")
        //     .parentElement.parentElement.querySelector(".cmp-market-picker__closer")
        //     .addEventListener("click", (e) => {
        //         document.querySelector(".investors:not(.hidden)").classList.add("hidden");
        //         document
        //             .querySelector(".cmp-market-picker__wrapper__investors-container")
        //             .classList.remove("country-selected");
        //         // document.querySelector('.mobile-wrapper').setAttribute('data-before', 'Select country');
        //     });

        window.addEventListener("load", () => {
            document.querySelector('.cmp-market-picker').classList.remove('no-js');
            // if (document.querySelector('.author')) {
            //     let authorqs = "?wcmmode=disabled"
            // }
            if (document.referrer == "") {
                cookiehelper.set("email-link", true);
            } else {
                cookiehelper.set("email-link", false);
            }
            const cookied_country = cookiehelper.get(cookieNameCountry)
                ? cookiehelper.get(cookieNameCountry).toLowerCase()
                : null;
            const cookied_language = cookiehelper.get(cookieNameLanguage)
                ? cookiehelper.get(cookieNameLanguage).toLowerCase()
                : null;
            const cookied_investor_type = cookiehelper.get(cookieNameInvestorType)
                ? cookiehelper.get(cookieNameInvestorType).toLowerCase()
                : null;

            const current_site_country = document
                .querySelector("[data-site-country]")
                .dataset.siteCountry.toLowerCase()
                .toLowerCase();
            const current_site_language = document
                .querySelector("[data-site-language]")
                .dataset.siteLanguage.toLowerCase()
                .toLowerCase();
            const current_site_investor_type = document
                .querySelector("[data-site-investor-type]")
                .dataset.siteInvestorType.toLowerCase()
                .toLowerCase();

            if (
                document
                    .querySelector("html")
                    .classList.toString()
                    .indexOf("aem-AuthorLayer-") == -1 &&
                !document.querySelector("html").hasAttribute("data-mode") &&
                current_site_country != "master"
            ) {
                if (
                    (current_site_country == "global" &&
                        cookiehelper.get(`soscot_null_en_all_terms_agreed`) != "true") ||
                    current_site_country != "global"
                ) {
                    if (
                        !cookied_country ||
                        !cookied_language ||
                        !cookied_investor_type ||
                        cookied_country != current_site_country ||
                        cookied_language != current_site_language ||
                        cookied_investor_type != current_site_investor_type
                        || !cookiehelper.get(`soscot_${current_site_country}_${current_site_language}_${current_site_investor_type}_terms_agreed`)) {

                        // test if an agreed terms cookie exists
                        let termsAgreedCookieSet = false;
                        document.cookie.split(" ").forEach(cookie => { if (cookie.indexOf("_terms_agreed") > -1) termsAgreedCookieSet = true });
                        // open market picker as there are no cookies set

                        if ((!termsAgreedCookieSet && current_site_country == "global")  ||
                            (termsAgreedCookieSet && cookiehelper.get(`soscot_${current_site_country}_${current_site_language}_${current_site_investor_type}_terms_agreed`) !== "true" && current_site_country != "global")
                        ) {
                            document.querySelector(".cmp-market-picker__opener").click();

                            if (current_site_country == "global") {
                                document
                                    .querySelector(
                                        '[data-market_investors="' + current_site_country + "_" + current_site_language + '"]'
                                    )
                                    .click();

                                cookiehelper.set("email-link", true);

                                document
                                    .querySelector(
                                        '[data-investor_type="' +
                                        current_site_investor_type +
                                        '"][data-country="' +
                                        current_site_country +
                                        '"][data-language="' +
                                        current_site_language +
                                        '"]'
                                    )
                                    .click();
                            }
                        }
                    }
                }
            }
        });
        window.addEventListener('load', e => {
            if (window.innerWidth < 1060) {
                document.querySelector('#main-navigation').appendChild(document.querySelector('.cmp-market-picker-wrapper'))
            } else {
                if (document.querySelector('#main-navigation .cmp-market-picker-wrapper'))
                    document.querySelector('.cmp-market-picker').prepend(document.querySelector('.cmp-market-picker-wrapper'))
            }
            document.querySelector('.cmp-market-picker').classList.remove('hidden');
            document.querySelector('.cmp-market-picker .icons') ? document.querySelector('.cmp-market-picker .icons').classList.remove('hidden') : document.querySelector('.cmp-header_v2 .icons').classList.remove('hidden');
        });
        window.addEventListener('resize', e => {
            if (window.innerWidth < 1060) {
                document.querySelector('#main-navigation').appendChild(document.querySelector('.cmp-market-picker-wrapper'))
            } else {
                if (document.querySelector('#main-navigation .cmp-market-picker-wrapper'))
                    document.querySelector('.cmp-market-picker').prepend(document.querySelector('.cmp-market-picker-wrapper'))
            }
        });
    }
    /** TODO set the cookies prior to navigating user away to their selected region */
    /** TODO multilingual markets should be by default cosed and indicated with chevron pointing down (i think :) - done */
})();
