/**
 * Text JavaScript
 */

import handleAnchor from "../../../../../../../clientlibs/v1/publish/js/base/anchor";
import { isInternalLink } from "../../../../../../../clientlibs/v1/publish/js/utils/aem";
import { nodeListArray } from "../../../../../../../clientlibs/v1/publish/js/utils/dom";

const targetSelector = `[data-component='text']`;
const components = nodeListArray(document.querySelectorAll(targetSelector));
function addTextListener(element) {
    element.addEventListener(
        "click",
        (e) => {
            const { target } = e;
            if (!isInternalLink(target) || !target.matches("a[href^='#']")) {
                return;
            }
            handleAnchor(target.getAttribute("href"));
        },
        false
    );
}

function init() {
    components.forEach((component) => {
        addTextListener(component);
    });
}

export default (function createTexts() {
    if (components && !components.length) {
        return;
    }
    init();
    document.querySelectorAll('.hide-show').forEach(item => {
        let itemId = crypto.randomUUID();
        item.parentNode.querySelector('.text').setAttribute('aria-expanded', true);
        item.setAttribute('aria-controls', itemId);
        item.parentNode.querySelector('.text').setAttribute('id', itemId);
        item.addEventListener('click', e => {
            e.currentTarget.classList.toggle('closed');
            if (e.currentTarget.parentNode.querySelector('.text').classList.contains('collapse')) {
                e.currentTarget.parentNode.querySelector('.text').classList.remove('collapse');
                item.parentNode.querySelector('.text').setAttribute('aria-expanded', false);
            } else {
                e.currentTarget.parentNode.querySelector('.text').classList.add('collapse');
                item.parentNode.querySelector('.text').setAttribute('aria-expanded', true);
            }
        })
    })
})();
