'use strict';
(function ($) {
    var defaultOptions = {
        containerClass: 'soscot-chart__container',
        titleClass: 'soscot-chart__title',
        filterButtonSelector: '.js-bse-soscot-cta.button--tertiary',
        resetButtonSelector: '.js-bse-soscot-cta.button--secondary',
        fromDatePickerSelector: '.js-soscot-chart__from-date-picker',
        toDatePickerSelector: '.js-soscot-chart__to-date-picker',
        minDate: '01/01/1995',
        maxDate: '12/31/2024',
        soscotFormSelector: '#soscot-chart-form',
        soscotTable: 'soscot-chart__js-table',
        tableContainer: 'soscot-chart__summary',
        unitLabelDesktop: '.js-soscot-chart__unit-desktop',
        performanceTable: '.js-soscot-performance-table'
    };

    var pick = Highcharts.pick,
      each = Highcharts.each,
      splat = Highcharts.splat,
      newHeight = $("#soscot-charting-box").height();
    /**
     * Initializes the stock chart component
     *
     * @class
     * @param {object} $soscotChart jQuery handle for element.
     * @param {object} options Component options.
     */
    function SoscotChart($soscotChart, options) {
        this.options = $.extend({}, defaultOptions, options);

        // Elements
        this.$soscotChart = $soscotChart;
        this.$form = $soscotChart.find(this.options.soscotFormSelector);
        this.$parentChart = $soscotChart.closest(".js-soscot-charting");
        this.$parentForm = this.$parentChart.find(this.options.soscotFormSelector);
        this.$filterButton = this.$parentForm.find(this.options.filterButtonSelector);
        this.$resetButton = this.$parentForm.find(this.options.resetButtonSelector);
        this.$fromDatePicker = this.$parentForm.find(this.options.fromDatePickerSelector);
        this.$toDatePicker = this.$parentForm.find(this.options.toDatePickerSelector);
        this.$soscotTable = this.$parentForm.find(this.options.soscotTable);
        this.$unitLabelDesktop = this.$parentForm.find(this.options.unitLabelDesktop);
        this.$performanceTable = this.$parentChart.find(this.options.performanceTable);
        this.charts = [];
        this.chartInitialized = false;
        //this.buildPerformanceTable();
        // Bind events
        this.bindEvents();
        this.$fromDatePicker.datepicker({
                        autoclose: true,
                        dateFormat: "dd/mm/yy",
                        changeMonth: true,
                        changeYear: true,
                        yearRange: "1995:+0",
                        maxDate: '0',
                        beforeShow: function() {
                            $(this).datepicker("widget").addClass("custom-datepicker-two");
                             setTimeout(function() {
                            if (!$("#ui-datepicker-div .additional-div").length) {
                                $("#ui-datepicker-div").prepend(`
                                    <div class="additional-div"><h2>Choose a start date</h2><span class="close-icon">&times;</span></div>
                                `);

                                // Close the Datepicker when the close icon is clicked
                                $("#ui-datepicker-div .close-icon").on('click', function() {
                                    $("#ui-datepicker-div").hide();
                                });
                            }
                        }, 0);
                        },
                            onChangeMonthYear: function(year, month, inst) {
                            // Close the Datepicker after changing month or year
                             setTimeout(function() {
                            if (!$("#ui-datepicker-div .additional-div").length) {
                                $("#ui-datepicker-div").prepend(`
                                    <div class="additional-div"><h2>Choose a start date</h2><span class="close-icon">&times;</span></div>
                                `);

                                // Close the Datepicker when the close icon is clicked
                                $("#ui-datepicker-div .close-icon").on('click', function() {
                                    $("#ui-datepicker-div").hide();
                                });
                            }
                        }, 0);
                        },
                        onOpen: function() {
                            // Show the close icon when Datepicker is opened
                            $("#closeIcon").show();
                            $("#additionalDiv").show();
                        },
                        onClose: function(dateText, inst) {
                            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
                            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
                            //$(this).datepicker('setDate', new Date(year, month, 1));
                            $(this).removeClass("active");
                            $(this).datepicker("widget").removeClass("custom-datepicker-two");
                            }
                        }).focus(function() {
                            $("#ui-datepicker-div").show();
                            $(this).datepicker("widget").addClass("custom-datepicker-two");
                            $(this).addClass("active");
                            $("#closeIcon").show();
                            $("#additionalDiv").show();
        });
        this.$toDatePicker.datepicker({
                        autoclose: true,
                        dateFormat: "dd/mm/yy",
                        changeMonth: true,
                        changeYear: true,
                        yearRange: "1995:+0",
                        maxDate: '0',
                        beforeShow: function() {
                            $(this).datepicker("widget").addClass("custom-datepicker-two");
                             setTimeout(function() {
                            if (!$("#ui-datepicker-div .additional-div").length) {
                                $("#ui-datepicker-div").prepend(`
                                    <div class="additional-div"><h2>Choose an end date</h2><span class="close-icon">&times;</span></div>
                                `);

                                // Close the Datepicker when the close icon is clicked
                                $("#ui-datepicker-div .close-icon").on('click', function() {
                                    $("#ui-datepicker-div").hide();
                                });
                            }
                        }, 0);
                        },
                            onChangeMonthYear: function(year, month, inst) {
                            // Close the Datepicker after changing month or year
                             setTimeout(function() {
                            if (!$("#ui-datepicker-div .additional-div").length) {
                                $("#ui-datepicker-div").prepend(`
                                    <div class="additional-div"><h2>Choose an end date</h2><span class="close-icon">&times;</span></div>
                                `);

                                // Close the Datepicker when the close icon is clicked
                                $("#ui-datepicker-div .close-icon").on('click', function() {
                                    $("#ui-datepicker-div").hide();
                                });
                            }
                        }, 0);
                        },
                        onOpen: function() {
                            // Show the close icon when Datepicker is opened
                            $("#closeIcon").show();
                            $("#additionalDiv").show();
                        },
                        onClose: function(dateText, inst) {
                            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
                            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
                            //$(this).datepicker('setDate', new Date(year, month, 1));
                            $(this).removeClass("active");
                            $(this).datepicker("widget").removeClass("custom-datepicker-two");
                            }
                        }).focus(function() {
                            $("#ui-datepicker-div").show();
                            $(this).datepicker("widget").addClass("custom-datepicker-two");
                            $(this).addClass("active");
                            $("#closeIcon").show();
                            $("#additionalDiv").show();
        });
        var self = this;
        var url = '/content/dam/cfsgam/soscot/charting.json';
        $.getJSON(url, function (data) {
            var chartData = data,
                summary = chartData["soscot-chart"][0]["summary"],
                lastClose = summary["lastClose"],
                lastNav = summary["lastNav"],
                lastDate = summary["lastDate"],
                $soscotTableContainer = '<div class="aem-GridColumn aem-GridColumn--default--12"><h2 class="chart_summary__title">Summary</h2></div>',
                $lastClose = '<div class="aem-GridColumn aem-GridColumn--default--12"><span>Last Close (pence): '+lastClose+'</span></div>',
                $lastNav = '<div class="aem-GridColumn aem-GridColumn--default--12"><span>Last NAV (pence): '+lastNav+'</span></div>',
                $lastDate = '<div class="aem-GridColumn aem-GridColumn--default--12"><span>Date: '+lastDate+'</span></div>';

            self.$parentForm.find(".soscot-chart__js-table").append($soscotTableContainer + $lastClose + $lastNav + $lastDate);

            $('[data-chart]').each(function () {
                var $chart = $(this),
                    chartName = $chart.data('chart'),
                    chartDatas = chartData[chartName];
                if (chartDatas !== undefined) {
                    self.buildChart(chartDatas);
                }
            });
        });
    }

    SoscotChart.prototype.bindEvents = function () {
        var self = this;
        self.$filterButton.on('click', function (e) {
            var formData = self.$parentForm.serializeArray();
            e.preventDefault();
            self.updateChart(formData, self.$fromDatePicker.val(), self.$toDatePicker.val());
        });

        self.$resetButton.on('click', function (e) {
            e.preventDefault();
            var form = self.$parentForm;
            var dateSelector = form.find('.bse-general-form__select');
            var chart = self.charts[0];
            var formData = self.$parentForm.serializeArray();

            if (chart !== undefined) {
                $(chart.rangeSelector.minInput).val(self.options.minDate).change();
                $(chart.rangeSelector.maxInput).val(self.options.maxDate).change();
            }
            self.updateChart(formData, self.options.minDate, new Date().toLocaleDateString());
            $(form).find('.js-soscot-chart__from-date-picker').val('Choose a date');
            $(form).find('.js-soscot-chart__to-date-picker').val('Choose a date');
        });
};

    /**
     * Highlight a point by showing tooltip, setting hover state and draw crosshair
     */
    Highcharts.Point.prototype.highlight = function(event) {
      event = this.series.chart.pointer.normalize(event);
      this.onMouseOver(); // Show the hover marker
      this.series.chart.tooltip.refresh(this); // Show the tooltip
      this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };

    /**
     * Synchronize zooming through the setExtremes event handler.
     */
    function syncExtremes(e) {
        var thisChart = this.chart;

        if (e.trigger !== 'syncExtremes') { // Prevent feedback loop
            Highcharts.each(Highcharts.charts, function (chart) {
                if (chart !== thisChart) {
                    if (chart.xAxis[0].setExtremes) { // It is null while updating
                        chart.xAxis[0].setExtremes(e.min, e.max,
                            undefined, false, { trigger: 'syncExtremes' }
                        );
                    }
                }
            });
        }
    }

Highcharts.Tooltip.prototype.refresh = function(pointOrPoints, mouseEvent) {
  var tooltip = this,
    label,
    options = tooltip.options,
    x,
    y,
    point = splat(pointOrPoints),
    anchor,
    textConfig = {},
    text,
    pointConfig = [],
    formatter = options.formatter || tooltip.defaultFormatter,
    shared = tooltip.shared,
    currentSeries;

  if (!options.enabled || !splat(point)[0]) {
    return;
  }

  // get the reference point coordinates (pie charts use tooltipPos)
  tooltip.followPointer = splat(point)[0].series.tooltipOptions
    .followPointer;
  anchor = tooltip.getAnchor(point, mouseEvent);
  x = anchor[0];
  y = anchor[1];

  // shared tooltip, array is sent over
  if (shared && !(point.series && point.series.noSharedTooltip)) {
    each(point, function(item) {
      item.setState('hover');

      pointConfig.push(item.getLabelConfig());
    });

    textConfig = {
      x: point[0].category,
      y: point[0].y
    };
    textConfig.points = pointConfig;
    point = point[0];

    // single point tooltip
  } else {
    textConfig = point.getLabelConfig();
  }
  this.len = pointConfig.length; // #6128
  text = formatter.call(textConfig, tooltip);

  // register the current series
  currentSeries = point.series;
  this.distance = pick(currentSeries.tooltipOptions.distance, 16);

  // update the inner HTML
  if (text === false) {
    this.hide();
  } else {

    label = tooltip.getLabel();

    // show it
    if (tooltip.isHidden) {
      label.attr({
        opacity: 1
      }).show();
    }

    // update text
    if (tooltip.split) {
      this.renderSplit(text, splat(pointOrPoints));
    } else {

      // Prevent the tooltip from flowing over the chart box (#6659)

      if (!options.style.width) {

        label.css({
          width: this.chart.spacingBox.width
        });

      }


      label.attr({
        text: text && text.join ? text.join('') : text
      });

      // Set the stroke color of the box to reflect the point
      label.removeClass(/highcharts-color-[\d]+/g)
        .addClass(
          'highcharts-color-' +
          pick(point.colorIndex, currentSeries.colorIndex)
        );


      label.attr({
        stroke: (
          options.borderColor ||
          point.color ||
          currentSeries.color ||
          '#666666'
        )
      });


      tooltip.updatePosition({
        plotX: x,
        plotY: y,
        negative: point.negative,
        ttBelow: point.ttBelow,
        h: anchor[2] || 0
      });
    }

    this.isHidden = false;
  }
};
    /**
     * Creates the chart from chart data passed.
     *
     * @function
     */
    SoscotChart.prototype.buildChart = function (chartDatas) {
        var self = this,
            inDesktopView = false,
            chart,title;

        if (!Array.isArray(chartDatas)) {
            chartDatas = [chartDatas];
        }

        this.$soscotChart.empty();
        this.chartInitialized = true;

        if ($(window).width() > 1024) {
            inDesktopView = true;
        }

        chartDatas.forEach(function (chartData, index) {
            if (index === 0) { return true; }
            var $chartContainer = $('<div style="margin-top:20px;margin-bottom:20px;" class="' + self.options.containerClass + '" id="soscot-chart-' + index + '"></div>'),
            $title = $('<h2 class="' + self.options.titleClass + '">' + chartData.title + '</h2>'),
            yAxisTitle = "",unitName = "";
            if (index == 1) {
                yAxisTitle = "Price(pence)";
                unitName = "(p)";
                if (chartData.title !== undefined) {
                    if (chartData.title !== "Soscot charting") {
                        title = $title;
                    }
                }
            } else if (index == 2) {
                yAxisTitle = "% Discount (-)/Premium (+) to NAV";
                unitName = "%";
                if (chartData.title !== undefined) {
                    if (chartData.title !== "Soscot charting") {
                        self.$soscotChart.append($title);
                    }
                }
            }

            self.$soscotChart.append($chartContainer);

            Highcharts.setOptions({
                lang:{
                    rangeSelectorZoom: ''
                },
            });

            chart = Highcharts.stockChart('soscot-chart-' + index, {
                chart: {
                    type: 'spline',
                    height: '500px',
                    backgroundColor: '#fafafa',
                    plotBorderColor: '#c8c8c8',
                    plotBorderWidth: 1,
                    style: {
                      overflow: 'visible',
                      'font-family': '"NotoSans",sans-serif',
                      'font-weight': 300
                    },
                    events: {
                        load: function(){
                          $(".highcharts-legend-item path").attr('stroke-width',8);
                        },
                         redraw: function () {
                            $(".highcharts-legend-item path").attr('stroke-width', 8);
                        }
                    }
                },
                colors: [
                    '#392A74',
                    '#25CAD2',
                    '#C4CDD3',
                    '#CD66FF',
                    '#F58232'
                ],
                credits: {
                    enabled: false
                },
                legend: {
                    align: 'left',
                    enabled: true,
                    itemDistance: 50,
                    itemStyle: {
                        'color': '#005288',
                        'cursor': 'pointer',
                        'fontSize': '16px',
                        'fontWeight': '300',
                        'textOverflow': 'clip'
                    },
                    layout: 'horizontal',
                    verticalAlign: 'bottom', // default 'bottom' cause the graph and navigator to get squished
                    //y: 150, // NOTE: this is to base align to match design
                    //itemMarginTop: 12,
                    //width: 242, // this is width for largest screen
                    title: {
                        style: {
                            'color': '#005288',
                            'fontSize': '16px',
                            'fontWeight': '300'
                        }
                    }
                },
                navigation: { // for exporting chart
                    buttonOptions: {
                        enabled: false
                    }
                },
                navigator: {
                    enabled: false
                },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 767
                            },
                            chartOptions: {
                                chart: {
                                    width: undefined
                                },
                                legend: {
                                    floating: false,
                                    align: 'left',
                                    layout: 'horizontal',
                                    verticalAlign: 'bottom',
                                    y: 0,
                                    width: undefined
                                }
                            }
                        }
                    ]
                },
                rangeSelector: {
                    buttons: [],
                    height: 0, // this puts the inputs inside the graph area
                    // NOTE: below is to hide inputs
                    inputStyle: {
                        display: 'none'
                    },
                    labelStyle: {
                        display: 'none'
                    }
                },
                scrollbar: {
                    enabled: false
                },
                series:  chartData.data_set,
                tooltip: {
                  shared: true,
                  split: false,
                  valueDecimals: 2,
                  style: {
                    width: 'auto'
                  },
                  headerFormat: '<b>Price Chart date: {point.x:%d/%m/%Y}</b><br>',
                  pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}'+unitName+'</b><br/>',
                },
                plotOptions: {
                    spline: {
                        series: {
                            lineWidth: 1.55,
                             states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        },
                        marker: {
                            enabled: true,
                            states: {
                                hover: {
                                    lineWidth: 2
                                }
                            }
                        }
                    }
                },
                xAxis: {
                    crosshair: true,
                    events: {
                        setExtremes: syncExtremes
                    }
                },
                yAxis: [{
                    title: {
                        text: yAxisTitle,
                    },
                    labels: {
                        align: 'right',
                        x: -10
                    },
                    opposite: false,
                    plotLines: [{
                        value: 0,
                        width: 2,
                        color: 'silver'
                    }],
                    tickAmount: 7,
                    startOnTick: false
                }]
            });
            self.charts.push(chart);
        });
        $("#soscot-charting-box").prepend(title);
    };

    /**
     * Get the dates (to and from) inputed from the dropdowns
     *
     * @function
     */
    SoscotChart.prototype.updateChart = function (data, fromDate, toDate) {
        var self = this,
            from,
            to,
            min,
            max,
            noPriceforMinDate = false,
            noPriceforMaxDate = false,
            noPrice = false;
        if (data != undefined) {

            if (this.chartInitialized) {
                    from = fromDate;
                    to = toDate;
                    min = fromDate;
                    max = toDate;
                    // Show Alert if date from is greater than Year To
                    if ((new Date(from)) >= (new Date(to))) {
                           alert("Year From must be earlier than Year To");
                           return false;
                    }
                    var minParts = min.split('/');
                    min = minParts[1] + "/" + minParts[0] + "/" + minParts[2];
                    var maxParts = max.split('/');
                    max = maxParts[1] + "/" + maxParts[0] + "/" + maxParts[2];
                    self.charts.forEach(function (chart) {
                        var maxDateInJSON = Date.parse($(chart.rangeSelector.maxInput).val());
                        var maxDateSelected = Date.parse(max);
                        var minDateInJSON = Date.parse($(chart.rangeSelector.minInput).val());
                        var minDateSelected = Date.parse(min);
                        if (minDateSelected > maxDateInJSON && !noPriceforMinDate) {
                            noPriceforMinDate = true;
                        } if (maxDateSelected > maxDateInJSON && !noPriceforMaxDate) {
                             noPriceforMaxDate = true;
                        }
                        if ((noPriceforMinDate && !noPriceforMaxDate) || (noPriceforMinDate && noPriceforMaxDate) && !noPrice) {
                            alert("No price information for the date selected");
                            noPrice = true;
                            return false;
                        } else {
                            $(chart.rangeSelector.minInput).val(min).change();
                            $(chart.rangeSelector.maxInput).val(max).change();
                        }
                        chart.xAxis[0].setExtremes(minDateSelected, maxDateSelected,
                            undefined, false, { trigger: 'syncExtremes' }
                        );
                    });
            }
        }
    }

    // Package as jQuery plugin.
    $.fn.SoscotChart = function (input) {
        var inputArguments = Array.prototype.slice.call(arguments, 1),
            returnValue;

        this.each(function () {
            if ((input === undefined || typeof input === 'object') && this.SoscotChart === undefined) {
                this.SoscotChart = new SoscotChart($(this), input);
                returnValue = $(this);
            } else if (typeof input === 'string') {
                returnValue = this.SoscotChart[input].apply(this.SoscotChart, inputArguments);
            }
        });

        return returnValue;
    };

    // Initialize elements with default class.
    $('.js-soscot-chart').SoscotChart();
}(jQuery));